import React from 'react';

/** ****************************** COMPONENTS  *********************88 */
import Card from 'components/Cards/Card';
import { Box,SimpleGrid , useColorModeValue, Icon, Flex,Heading } from '@chakra-ui/react';
import CustomTable from 'components/Table/CustomTable';
import Filtermenu from 'components/Filters/Filtermenu';
import IconBox from 'components/icons/IconBox';
import MiniStatistics from 'components/Cards/MiniStatistics';
import { LENDER_ROLES_LIST } from 'constants/roles';

import {
    MdAdminPanelSettings,
    MdSupervisedUserCircle,
    MdManageAccounts,
    MdBusiness
} from 'react-icons/md';
import BackButton from 'components/Button/BackButton';
/** ****************************** HELPERS AND APIES  ********************** */
import { useGetCompanyStatsQuery , useGetCompanyUsersQuery } from 'reducers/company.service';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { getusersHeader } from 'components/Table/Headers/Company/companyTableHeader';
import RefreshIcon from 'components/icons/RefreshIcon';

const CompanyDashboard = () => {
    const { id } = useParams();
    const [role , setrole] = React.useState('');

    const { data , refetch,isLoading: isCompanyStatsLoading,isError: isCompanyStatsError } = useGetCompanyStatsQuery(id);
    const { data: users, refetch: refetchUsers,isLoading: isCompanyUsersLoading,isError: isCompanyUsersError } = useGetCompanyUsersQuery({ id , role });

    const companyTableHeader = getusersHeader({ tableActions: [] });

    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const handleSelect = (value)=>{
        setrole(value);
    };
    const handleRefresh = ()=>{
        refetch();
        refetchUsers();
    };
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '65px' }}>
            <Card mb={4} p={1}>
                <Flex justifyContent={'space-between'} >
                    <Flex alignItems={'center'} gap={5}>
                        <BackButton />
                        <Heading size='md' color='var(--primary-color)' >View Stats</Heading>
                    </Flex>
                    <RefreshIcon handleRefresh={refetch}/>
                </Flex>
            </Card>
            <SimpleGrid
                mb="20px"
                columns={{ sm: 1, md: 2 }}
                spacing={{ base: '20px', xl: '20px' }}
            >

                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdBusiness}
                                    color={'green.500'}
                                />
                            }
                        />
                    }
                    name="Total Branches"
                    value={data?.noOfBranchs || 0}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdAdminPanelSettings}
                                    color={brandColor}
                                />
                            }
                        />
                    }
                    name="Total Company Admins"
                    value={data?.noOfCompanyAdmin || 0 }
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdSupervisedUserCircle}
                                    color="purple.500"
                                />
                            }
                        />
                    }
                    name="Total MLOs"
                    value={data?.mlo || 0 }
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w="56px"
                            h="56px"
                            bg={boxBg}
                            icon={
                                <Icon
                                    w="32px"
                                    h="32px"
                                    as={MdManageAccounts}
                                    color="cyan.500"
                                />
                            }
                        />
                    }
                    name="Total Branch Managers"
                    value={data?.noOfBranchManager || 0 }
                />
            </SimpleGrid>
            <Flex mb="20px">
                <Box w="100%">
                    <Card>
                        <div className="space-between-box">
                            <div>
                                <p>Company Users</p>
                            </div>
                            <div>
                                <Filtermenu selected={role} list={LENDER_ROLES_LIST} handleChange={handleSelect} />
                            </div>
                        </div>
                        <CustomTable rowStyle={{ cursor: 'pointer' }} handleRefresh={handleRefresh} data={users} columns={companyTableHeader} isError={isCompanyStatsError && isCompanyUsersError} isLoading={isCompanyStatsLoading && isCompanyUsersLoading} />
                    </Card>
                </Box>
            </Flex>

        </Box>
    );
};

export default CompanyDashboard;
