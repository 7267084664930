export const CONSUMER_ROLE = 'CONSUMER_ROLE';
export const LENDER_ROLE = 'LENDER_ROLE';
export const LENDER_MASTER_ADMIN_ROLE = 'MASTER_ADMIN';
export const LENDER_COMPANY_ADMIN_ROLE = 'COMPANY_ADMIN';
export const LENDER_BRANCH_ADMIN_ROLE = 'BRANCH_ADMIN';
export const LENDER_BRANCH_MANAGER_ROLE = 'BRANCH_MANAGER';
export const LENDER_PROCESSORS_ROLE = 'PROCESSOR';
export const LENDER_MLO_ROLE = 'MLO';

export const LENDER_ROLES_LIST = [
    {
        value: '',
        label: 'All'
    },
    {
        value: LENDER_MASTER_ADMIN_ROLE ,
        label: 'Master Admin'
    },
    {
        value: LENDER_COMPANY_ADMIN_ROLE ,
        label: 'Company Admin'
    },
    {
        value: LENDER_BRANCH_ADMIN_ROLE ,
        label: 'Branch Admin'
    },
    {
        value: LENDER_BRANCH_MANAGER_ROLE ,
        label: 'Branch Manager'
    },
    {
        value: LENDER_PROCESSORS_ROLE ,
        label: 'Processor'
    },
    {
        value: LENDER_MLO_ROLE ,
        label: 'Mlo'
    }
];
