import React from 'react';
import sortCaret from 'components/Table/Others/sortCaret';
import { Link } from '@chakra-ui/react';
import ActionsFormatter from 'components/Table/Fomatters/actionsFormatter';
import { viewDate , viewDateAndTime } from 'utils/date';

export const getCompanyTableHeader = ({ tableActions })=>{
    return [
        {
            text: 'Ticket ID',
            dataField: 'ticketId',
            sortCaret,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Link href={'/organization/approvals/view-request/' + row._id} color={'brand.500'}>
                        {cell}
                    </Link>
                );
            } ,
            formatExtraData: tableActions
        },
        {
            text: 'Company Name',
            dataField: 'companyName',
            sort: false,
            sortCaret,
            formatter: (value,row)=>{
                return (
                    <Link href={'/organization/company/view-company/' + row?.companyId} color={'brand.500'}>
                        {value}
                    </Link>
                );
            }
        },
        {
            text: 'Type',
            dataField: 'type',
            sort: true,
            sortCaret
        },

        {
            text: 'Status',
            dataField: 'status',
            sort: true,
            sortCaret
        },
        {
            text: 'CreatedAt',
            dataField: 'updatedAt',
            sort: true,
            sortCaret,
            formatter: (value)=>{
                return viewDate(value);
            }
        },
        {
            text: 'Action',
            dataField: '_id',
            sortCaret,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                if (row.status !== 'PENDING') {
                    return (
                        <Link href={'/organization/approvals/view-request/' + cell} color={'brand.500'}>
                        View Details
                        </Link>
                    );
                }
                return (<ActionsFormatter cell={cell} row={row} rowIndex={rowIndex} formatExtraData={formatExtraData}/>);
            } ,
            formatExtraData: tableActions
        }

    ];
};
export const allcompaniesHeader = ({ tableActions })=>{
    return [
        {
            text: 'Company Id',
            dataField: 'companyId',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Link href={'/organization/company/view-company/' + row._id} color={'brand.500'}>
                        {cell}
                    </Link>
                );
            },
            sort: true,
            sortCaret

        },
        {
            text: 'Name',
            dataField: 'name',
            formatter: (value,row)=>{
                return (
                    <>
                        { value }
                    </>
                );
            },
            sort: true,
            sortCaret
        },
        {
            text: 'NMLS',
            dataField: 'NMLS',
            sortCaret,
            sort: true

        },
        {
            text: 'Status',
            dataField: 'isActive',
            sortCaret,
            formatter: (value)=>{
                return value === true
                    ? <p className='success-text'>
ACTIVE
                    </p>
                    : <p className='danger-text'>
                BLOCK
                    </p>;
            },
            sort: true

        },
        {
            text: 'On Boarding',
            dataField: 'isCompanySetup',
            sortCaret,
            formatter: (value)=>{
                if (value === true) {
                    return (
                        <p className='success-text'>COMPLETED</p>
                    );
                }
                return 'IN PROGRESS';
            }
        },
        {
            text: 'CreatedAt',
            dataField: 'createdAt',
            sortCaret,
            formatter: (value)=>{
                return viewDateAndTime(value);
            }
        },
        {
            text: 'Action',
            dataField: 'updatedAt',
            formatter: (value, row)=>{
                return (
                    <Link href={'/organization/company/stats/' + row._id} color={'brand.500'}>
View Stats
                    </Link>
                );
            }
        }

    ];
};
export const allRealtorHeader = ({ tableActions })=>{
    return [
        {
            text: 'Id',
            dataField: 'realtorId',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Link href={'/organization/realtor/view-realtor/' + row._id} color={'brand.500'}>
                        {cell || ''}
                    </Link>
                );
            },
            sort: true,
            sortCaret

        },
        {
            text: 'Email',
            dataField: 'ContactPointEmailValue',
            sortCaret,
            sort: true

        },

        {
            text: 'First Name',
            dataField: 'FirstName',
            formatter: (value,row)=>{
                return (
                    <>
                        { value }
                    </>
                );
            },
            sort: true,
            sortCaret
        },
        {
            text: 'Last Name',
            dataField: 'LastName',
            sortCaret,
            sort: true

        },
        {
            text: 'On Board Type',
            dataField: 'onboardFrom',
            sortCaret,
            sort: true

        },
        {
            text: 'Status',
            dataField: 'accountStatus',
            sortCaret,
            formatter: (value)=>{
                return value === true
                    ? <p className='success-text'>
ACTIVE
                    </p>
                    : <p className='danger-text'>
                BLOCK
                    </p>;
            },
            sort: true

        },
        {
            text: 'CreatedAt',
            dataField: 'createdAt',
            sortCaret,
            formatter: (value)=>{
                return viewDateAndTime(value);
            }
        },
        {
            text: 'Action',
            dataField: 'updatedAt',
            formatter: (value, row)=>{
                return (
                    <Link href={'/organization/realtor/view-realtor/' + row._id} color={'brand.500'}>
View  Details
                    </Link>
                );
            }
        }

    ];
};

export const getusersHeader = ({ tableActions })=>{
    return [
        {
            text: 'Email',
            dataField: 'ContactPointEmailValue',
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return cell;
            },
            sort: true,
            sortCaret

        },
        {
            text: 'Mobile No',
            dataField: 'ContactPointTelephoneValue',
            formatter: (value,row)=>{
                return `+ ${row.ContactPointTelephonePrefix || ''} ${row.ContactPointTelephoneValue}`;
            },
            sort: true,
            sortCaret
        },
        {
            text: 'NMLS',
            dataField: 'NMLS',
            sortCaret,
            sort: true

        },
        {
            text: 'Status',
            dataField: 'accountStatus',
            sortCaret,
            formatter: (value)=>{
                return value === true
                    ? <p className='success-text'>
ACTIVE
                    </p>
                    : <p className='danger-text'>
                BLOCK
                    </p>;
            },
            sort: true

        },
        {
            text: 'CreatedAt',
            dataField: 'createdAt',
            sortCaret,
            formatter: (value)=>{
                return viewDateAndTime(value);
            }
        }

    ];
};
