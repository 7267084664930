import React from 'react';
import { Box,SimpleGrid,Avatar,Text,Tag } from '@chakra-ui/react';
import Card from 'components/Cards/Card';
import BackButton from 'components/Button/BackButton';
import RefreshIcon from 'components/icons/RefreshIcon';
import { getAddressView , getGenderView , getBooleanView , getNameView , getNumberView, getAvatarNameView } from 'utils/index';
import { useGetconsumerDetailsQuery } from 'reducers/consumer.service';
import 'assets/css/viewlayout.scss';
import 'assets/css/Preview.css';

import { useParams } from 'react-router-dom/cjs/react-router-dom';

import { viewDate , viewDateAndTime } from 'utils/date';
import { useGetDocumenturlMutation } from 'reducers/Document.service';
import LoadingDataCard from 'components/Cards/LoadingDataCard';

const ViewConsumer = ()=>{
    const { id } = useParams();
    const [avatar , setavatar] = React.useState(null);
    const [getDocumentUrl] = useGetDocumenturlMutation();
    const { data, refetch,isLoading } = useGetconsumerDetailsQuery(id);
    React.useEffect(()=>{
        if (data && data?.avatar) {
            getDocumentUrl({ url: data?.avatar }).unwrap().then((res) => {
                setavatar(res);
            }).catch(()=>{
                setavatar(null);
            });
        }
    }, [data]);
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '65px' }}>
            {
                isLoading
                    ? <Card p={0}>
                        <LoadingDataCard />
                    </Card>
                    : <SimpleGrid
                        mb="20px"
                        columns={{ sm: 1, md: 1 }}
                        spacing={{ base: '20px', xl: '20px' }}
                    >
                        <Card p={0}>
                            <Box bgColor={'var(--primary-color)'} w="100%" h='20vh'>
                                <div className="space-between-box page-head-box-user-view">
                                    <BackButton className='button-color'/>
                                    <div>
                                        <RefreshIcon handleRefresh={refetch} className='button-color'/>
                                    </div>
                                </div>
                            </Box>
                            <Box p='4'>
                                <Box p='2' mt={9}>
                                    <Text fontSize={'3xl'} fontWeight={'semibold'}>{getNameView(data)}</Text>
                                    <Text fontSize={'md'} mt={2}>{data?.ContactPointEmailValue}</Text>
                                    <Text mt={2}>{getNumberView(data)}</Text>
                                    <Tag colorScheme='teal' size='lg' mt={2}>{data?.userType}</Tag>
                                    <Text mt={2}>Joined On: {viewDate(data?.createdAt)}</Text>
                                </Box>
                            </Box>
                            <Avatar size='2xl' src= {avatar} name={getAvatarNameView(data)} position={'absolute'} bottom={'53%'} left={'2%'} />
                        </Card>
                        <Card p={4}>
                            <div className='preview-box'>
                                <div className="preview-heading">
                                    <p>Consumer Details</p>
                                </div>
                                <div className='preview-body' >
                                    <table>
                                        <tr>
                                            <td>
DID
                                            </td>
                                            <td>
                                                {data?.did}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                        Gender
                                            </td>
                                            <td>
                                                {getGenderView(data?.gender)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Occupency Type
                                            </td>
                                            <td>
                                                {data?.OccupencyType}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                        BirthDate
                                            </td>
                                            <td>
                                                {viewDate(data?.BirthDate)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                    Address
                                            </td>
                                            <td>
                                                {getAddressView(data)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
        Is Id Address Current Residence
                                            </td>
                                            <td>
                                                {getBooleanView(data?.isIdAddressCurrentResidence)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                    Is On Rent
                                            </td>
                                            <td>
                                                {getBooleanView(data?.isOwnPropertyId?.isOnRent)}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                        is Own Property
                                            </td>
                                            <td>
                                                {getBooleanView(data?.isOwnPropertyId?.isOwnProperty)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Last Updated On
                                            </td>
                                            <td>
                                                {viewDateAndTime(data?.updatedAt)}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </Card>
                    </SimpleGrid>
            }
        </Box>
    );
};

export default ViewConsumer;
