import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider,{ Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import NoDataCard from 'components/Cards/NoDataCard';
import LoadingDataCard from 'components/Cards/LoadingDataCard';
import ErrorCard from 'components/Cards/ErrorCard';
/** ****************** HELPERS ****************** **/
import RefreshIcon from 'components/icons/RefreshIcon';
/** ****************** STYLESHEETS ****************** **/
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'assets/css/Table.scss';

const CustomTable = ({ data = [], columns, handleRefresh, onClickRow, rowStyle, isLoading,isError }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage,setDataPerPage] = useState(25);

    const indexOfLastDataItem = currentPage * dataPerPage;
    const indexOfFirstDataItem = indexOfLastDataItem - dataPerPage;
    const currentData = data.slice(indexOfFirstDataItem, indexOfLastDataItem);
    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total custom-pagination-total">
            { from } to { to } of { size }
        </span>
    );

    const paginationOptions = {
        sizePerPage: dataPerPage,
        paginationSize: 3,
        firstPageText: 'First',
        lastPageText: 'Last',
        pageStartIndex: 0,
        hidePageListOnlyOnePage: true,
        alwaysShowAllBtns: true,
        nextPageText: '>',
        prePageText: '<',
        showTotal: true,
        sizePerPageList: [
            {
                text: '25',
                value: 25
            },
            {
                text: '30',
                value: 30
            },
            {
                text: 'All',
                value: data.length
            }
        ],
        paginationTotalRenderer: customTotal,
        onPageChange: (page, sizePerPage) => {
            setCurrentPage(page);
        },
        onSizePerPageChange: (sizePerPage, page) => {
            setCurrentPage(page);
            setDataPerPage(sizePerPage);
        },
        paginationListRenderer: ({ ...rest }) => {
            return (
                <ul className="pagination">
                    {rest.pages.map((page) => {
                        return rest.pageButtonRenderer({
                            page,
                            active: page === rest.currentPage,
                            onPageChange: rest.onPageChange
                        });
                    })}
                </ul>
            );
        }
    };

    const { SearchBar } = Search;
    return (
        <div className="table-container">
            <ToolkitProvider
                keyField="id"
                data={data}
                columns={columns}
                search={true}
            >
                {props => (
                    <>
                        <div className="space-between-box">
                            <SearchBar {...props.searchProps} searchIcon srText='' className='table-search-bar' />
                            <RefreshIcon handleRefresh={handleRefresh}/>
                        </div>
                        {
                            isError
                                ? <ErrorCard handleRefresh={handleRefresh}/>
                                : <BootstrapTable
                                    bootstrap4
                                    keyField='_id'
                                    data={currentData}
                                    columns={columns}
                                    pagination={paginationFactory(paginationOptions)}
                                    filter={filterFactory()}
                                    filterPosition='top'
                                    hover
                                    rowStyle={rowStyle}
                                    rowEvents={ onClickRow ? { onClick: onClickRow || null } : {}}
                                    noDataIndication={()=>isLoading ? <LoadingDataCard label={'Please wait while we load the data...'}/> : <NoDataCard label={data.length === 0 ? 'No Data Found' : `No search results for "${props.searchProps.searchText}"`}/>}
                                    wrapperClasses="table-responsive table-main-style"
                                    {...props.baseProps} />
                        }
                    </>
                )}
            </ToolkitProvider>
        </div>
    );
};

export default CustomTable;
