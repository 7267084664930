import React from 'react';
import 'assets/css/Preview.css';
import { Tag } from '@chakra-ui/react';
import NoDataCard from 'components/Cards/NoDataCard';
import LoadingDataCard from 'components/Cards/LoadingDataCard';
import PreviewDocument from './PreviewDocument';
import _ from 'lodash';
import { getBooleanView } from 'utils';
const ViewRecord = ({ name , value })=>{
    console.log('the name value ', typeof vlaue);
    if (name === 'w9' || name === 'boardResulation') {
        return (
            <tr>
                <td className='capital-text'>
                    {name}
                </td>
                <td>
                    <PreviewDocument doc={value}/>
                </td>
            </tr>
        );
    } else if (_.isBoolean(value)) {
        return (
            <tr>
                <td className='capital-text'>
                    {name}
                </td>
                <td>
                    {getBooleanView(value)}
                </td>
            </tr>
        );
    } else if (Array.isArray(value)) {
        return (
            <tr>
                <td className='capital-text'>
                    {name}
                </td>
                <td>
                    <div>
                        {
                            value.map((row)=>(
                                <Tag ml={2} colorScheme='teal' key={row.value} size='md'>{row.label}</Tag>
                            ))
                        }
                    </div>
                </td>
            </tr>
        );
    } else if (_.isObject(value)) {
        return (
            <>
                {
                    Object.keys(value).map((key, index)=>(
                        <tr key={index}>
                            <td>
                                {key}
                            </td>
                            <td>
                                {value[key]}
                            </td>
                        </tr>
                    ))
                }

            </>
        );
    } else {
        return (
            <tr>
                <td className='capital-text'>
                    {name}
                </td>
                <td>
                    {
                        Array.isArray(value)
                            ? <div>
                                {
                                    value.map((row)=>(
                                        <Tag colorScheme='teal' key={row.value} size='md'>{row.label}</Tag>
                                    ))
                                }
                            </div>
                            : value
                    }

                </td>
            </tr>
        );
    }
};
export default function PreviewCompanyChanges({ data,isLoading }) {
    return (
        <div className='preview-box'>
            <div className="preview-heading">
                <p>Preview Company Changes</p>
            </div>
            <div className='preview-body' >
                <table>
                    {
                        isLoading
                            ? <LoadingDataCard />
                            : data
                                ? Object.keys(data).map((name, index)=>(
                                    <ViewRecord name={name} key={index} value={data[name]}/>
                                ))
                                : <NoDataCard label={'No changes found'}/>
                    }

                </table>
            </div>
        </div>
    );
}
