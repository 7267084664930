import React from 'react';

/** ********************************** COMPONENTS  ******************* */
import { Tag, Grid, GridItem } from '@chakra-ui/react';
/** ********************************* HELPERS  ************************** */
import TabField from 'components/dataDispaly/TabField';
import { viewDate , viewDateAndTime } from 'utils/date';

import { viewFullName } from 'utils/name';
import { ViewMobileNumber } from 'utils/number';

export default function RealtorPersonal({ data }) {
    return (
        <div>
            <div className='preview-box'>
                <div className="preview-heading space-between-box">
                Personal Details
                </div>
                <div className='outline-table '>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                Realtor ID
                                </td>
                                <td>
                                    {data?.realtorId }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                  Name
                                </td>
                                <td className='capitalize-text'>
                                    {viewFullName(data) }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Email
                                </td>
                                <td>
                                    {data?.ContactPointEmailValue}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Phone
                                </td>
                                <td>
                                    {ViewMobileNumber(data)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Onboarding Type
                                </td>
                                <td>
                                    {data?.onboardFrom}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Account Setup
                                </td>
                                <td>
                                    {
                                        data?.indicator === 2
                                            ? <p className='success-text'>Complted</p>
                                            : <p className='danger-text'>In Progress</p>
                                    }

                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Account Status
                                </td>
                                <td>
                                    {
                                        data?.accountStatus === true
                                            ? <p className='success-text'>
                            ACTIVE
                                            </p>
                                            : <p className='danger-text'>
                BLOCK
                                            </p>
                                    }
                                </td>
                            </tr>
                            <tr>

                                <td>
                                    Bio
                                </td>
                                <td className='unstyled-text'>
                                    {data?.collateral?.biotext || 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Website
                                </td>
                                <td className='unstyled-text'>
                                    {data?.website || 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Joined myIDFi On
                                </td>
                                <td>
                                    {viewDate(data?.createdAt)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                Updated On
                                </td>
                                <td>
                                    {viewDate(data?.updatedAt)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="preview-box">
                <div className="preview-heading space-between-box">
                    State Licenses
                </div>

                <div className="outline-table">
                    {
                        (data && data?.Statelicenses.length > 0 && data?.Statelicenses?.map((state,index)=>(
                            <Tag colorScheme='teal' key={index} m={2} size='md'>{state.label}</Tag>
                        ))) || <TabField value={<p>Doesn&apos;t have any state license</p>}/>
                    }

                </div>
            </div>
            <div className="preview-box">
                <div className="preview-heading space-between-box">
                Market
                </div>
                <div className="outline-table">
                    {
                        (data && data?.market?.length > 0 && data?.market?.map((value,index)=>(
                            <Tag colorScheme='teal' key={index} size='md' m={2}>{value}</Tag>
                        ))) || <TabField value={<p>Doesn&apos;t have any Zipcode</p>}/>
                    }

                </div>
            </div>

            <div className="preview-box">
                <div className="preview-heading space-between-box">
                    Logo
                </div>
                <div className='content'>
                    <Grid templateColumns='repeat(5, 1fr)' >
                        <GridItem colSpan={3} >
                            {
                                data?.collateral?.logo
                                    ? <img loading={'lazy'} src={data?.collateral?.logo} style={{ width: '90%', height: '90%' }} alt="Logo" />
                                    : <p className='padding-box'>No Logo added</p>

                            }

                        </GridItem>
                        <GridItem colSpan={1} >
                            <p>Logo Added</p>
                            {viewDateAndTime(data?.createdAt)}
                        </GridItem>
                        <GridItem colSpan={1} >
                            <p>Last Updated On</p>
                            {viewDateAndTime(data?.updatedAt)}
                        </GridItem>

                    </Grid>

                </div>
            </div>
        </div>
    );
}
