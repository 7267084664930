import React from 'react';

/** *************** ICONS WHICH ARE USED IN THE PAGES  *********** */
import { Icon } from '@chakra-ui/react';
import {
    MdHome,
    MdAdminPanelSettings,
    MdSupervisedUserCircle,
    MdOutlineDocumentScanner,
    MdOutlineHomeWork
} from 'react-icons/md';
import { ImUserTie } from 'react-icons/im';
/** ********************** MAIN DASHBOARD  ***************** */
import MainDashboard from 'views/admin/default';
import OrgUserTables from 'views/admin/User';
import OrgCompany from 'views/admin/orgCompany/Allcompany';
import ViewRequests from 'views/admin/orgCompany/index';
import CompanyDashboard from 'views/admin/orgCompany/CompanyDashboard';

/** ************************** auth pages ***************** */
import SignInCentered from 'views/auth/signIn';
import ForgotPassword from 'views/auth/forgotPassword';
import ValidationUser from 'views/auth/validationUser';
import ResetPassword from 'views/auth/resetPassword';

import ApplicationDashboard from 'views/admin/Applications/Dashboard';
import ViewCompany from 'views/admin/orgCompany/viewCompany';
import Viewrequest from 'views/admin/orgCompany/Viewrequest';
import ViewUser from 'views/admin/User/ViewConsumer';

/** ***************** REALTOR PAGE *************** */
import RealtorHome from 'views/admin/Realtor/RealtorHome';
import RealtorDetails from 'views/admin/Realtor/RealtorDetails';

const routes = [
    {
        name: 'Dashboard',
        layout: '/organization',
        path: '/dashboard',
        icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
        component: MainDashboard,
        hideLink: false
    },

    {
        name: 'Application',
        layout: '/organization',
        path: '/application',
        icon: (
            <Icon
                as={MdOutlineDocumentScanner}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: ApplicationDashboard,
        hideLink: false
    },
    {
        name: 'Consumers',
        layout: '/organization',
        path: '/user/view-user/:id',
        icon: (
            <Icon
                as={MdSupervisedUserCircle}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: ViewUser,
        hideLink: true
    },
    {
        name: 'Consumers',
        layout: '/organization',
        path: '/user',
        icon: (
            <Icon
                as={MdSupervisedUserCircle}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: OrgUserTables
    },
    {
        name: 'View Company',
        layout: '/organization',
        path: '/company/view-company/:id',
        icon: (
            <Icon
                as={MdOutlineHomeWork}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: ViewCompany,
        hideLink: true
    },
    {
        name: 'View Company',
        layout: '/organization',
        path: '/company/stats/:id',
        icon: (
            <Icon
                as={MdOutlineHomeWork}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: CompanyDashboard,
        hideLink: true
    },
    {
        name: 'View Request',
        layout: '/organization',
        path: '/approvals/view-request/:id',
        icon: (
            <Icon
                as={MdOutlineHomeWork}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: Viewrequest,
        hideLink: true
    },
    {
        name: 'Approvals',
        layout: '/organization',
        path: '/approvals',
        icon: (
            <Icon
                as={MdOutlineHomeWork}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: ViewRequests,
        hideLink: false

    },
    {
        name: 'Company',
        layout: '/organization',
        path: '/company',
        icon: (
            <Icon
                as={MdOutlineHomeWork}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: OrgCompany
    },
    {
        name: 'View Realtor',
        layout: '/organization',
        path: '/realtor/view-realtor/:id',
        icon: (
            <Icon
                as={ImUserTie}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: RealtorDetails,
        hideLink: true
    },
    {
        name: 'Realtor',
        layout: '/organization',
        path: '/realtor',
        icon: (
            <Icon
                as={ImUserTie}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: RealtorHome,
        hideLink: false
    },
    {
        name: 'Signin',
        layout: '/auth',
        path: '/sign-in',
        icon: (
            <Icon
                as={MdAdminPanelSettings}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: SignInCentered,
        hideLink: true
    },
    {
        name: 'Forgot Password',
        layout: '/auth',
        path: '/forgot-password',
        icon: (
            <Icon
                as={MdAdminPanelSettings}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: ForgotPassword,
        hideLink: true
    },
    {
        name: 'Validation',
        layout: '/auth',
        path: '/validate-invitation',
        icon: (
            <Icon
                as={MdAdminPanelSettings}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: ValidationUser,
        hideLink: true
    },
    {
        name: 'Reset Password',
        layout: '/auth',
        path: '/reset-password/:userId/:token',
        icon: (
            <Icon
                as={MdAdminPanelSettings}
                width="20px"
                height="20px"
                color="inherit"
            />
        ),
        component: ResetPassword,
        hideLink: true
    }
];

export default routes;
