import { baseQuerySetup,getResponsedata,onQueryStarted, onCacheEntryAdded, transformResponse } from 'config/index';
import { createApi } from '@reduxjs/toolkit/query/react';
import { COMPANY_PREFIX } from 'constants/index';
let companyReducer = 'CompanyReducer';
let companyTag = 'companyTag';

export const companyApi = createApi({
    reducerPath: companyReducer,
    baseQuery: baseQuerySetup,
    tagTypes: [companyTag],
    endpoints: (builder) => ({
        getApprovals: builder.query({
            query: (type) => {
                if (type) {
                    return { url: COMPANY_PREFIX + '/approvals?type=' + type, method: 'GET' };
                } else {
                    return { url: COMPANY_PREFIX + '/approvals', method: 'GET' };
                }
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata,
            providesTags: [companyTag]
        }),
        approvalAction: builder.mutation({
            query: ({ id , body }) => {
                return { url: COMPANY_PREFIX + '/approvals/' + id, method: 'PUT', body };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse,
            invalidatesTags: [companyTag]
        }),
        getCompanyQuery: builder.query({
            query: (id) => {
                return { url: COMPANY_PREFIX + '/' + id, method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata,
            providesTags: [companyTag]
        }),
        getApprovalRequest: builder.query({
            query: (id) => {
                return { url: COMPANY_PREFIX + '/approvals/' + id, method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata
        }),
        getAllCompanies: builder.query({
            query: () => {
                return { url: COMPANY_PREFIX + '/list' , method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata
        }),
        getCompanyUsers: builder.query({
            query: ({ id , role }) => {
                if (role) {
                    return { url: COMPANY_PREFIX + '/' + id + '/lenders?role=' + role , method: 'GET' };
                } else {
                    return { url: COMPANY_PREFIX + '/' + id + '/lenders', method: 'GET' };
                }
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata
        }),
        getCompanyStats: builder.query({
            query: (id) => {
                return { url: COMPANY_PREFIX + '/' + id + '/stats' , method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata
        })
    })
});

export const { useGetApprovalsQuery , useApprovalActionMutation , useGetCompanyQueryQuery , useGetApprovalRequestQuery , useGetAllCompaniesQuery, useGetCompanyStatsQuery, useGetCompanyUsersQuery } = companyApi;
