import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { viewDate } from 'utils/date';
import { COMPANY_APPOVED_STATUS } from 'constants/action.status';
import 'assets/css/Preview.css';

export default function Previewrequest({ data }) {
    return (
        <div className='preview-box'>
            <div className="preview-heading">
                <p>Preview  Request </p>
            </div>
            <div className='preview-body' >
                <table>
                    <tr>
                        <td>Ticket Id</td>
                        <td>
                            <Link to={'/organization/company/view-company/' + data?.companyId} color={'brand.500'}>
                                {data?.ticketId}
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>Company Name</td>
                        <td>
                            <Link to={'/organization/company/view-company/' + data?.companyId} color={'brand.500'}>
                                {data?.companyName}
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Type
                        </td>
                        <td>
                            {data?.type}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Status
                        </td>
                        <td className={data?.status === COMPANY_APPOVED_STATUS ? 'success-status' : 'rejected-status'} >
                            {data?.status}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Message from lender
                        </td>
                        <td>
                            {data?.message}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Created At
                        </td>
                        <td>
                            {viewDate(data?.createdAt)}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
}
