import React from 'react';

// Chakra imports
import {
    Icon,
    Flex,
    Text,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    useDisclosure,
    useColorModeValue
} from '@chakra-ui/react';
// Assets
import { MdFilterAlt } from 'react-icons/md';

export default function Filtermenu({ selected , setselected , list, handleChange }) {
    const textColor = useColorModeValue('secondaryGray.500', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const textHover = useColorModeValue(
        { color: 'secondaryGray.900', bg: 'unset' },
        { color: 'secondaryGray.500', bg: 'unset' }
    );
    const bgList = useColorModeValue('white', 'whiteAlpha.100');
    const bgShadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
        'unset'
    );
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue(
        { bg: 'secondaryGray.400' },
        { bg: 'whiteAlpha.50' }
    );
    const bgFocus = useColorModeValue(
        { bg: 'secondaryGray.300' },
        { bg: 'whiteAlpha.100' }
    );

    // Ellipsis modals
    const {
        isOpen: isOpen1,
        onOpen: onOpen1,
        onClose: onClose1
    } = useDisclosure();

    return (
        <Menu isOpen={isOpen1} onClose={onClose1}>
            <MenuButton
                align="center"
                justifyContent="center"
                bg={bgButton}
                _hover={bgHover}
                _focus={bgFocus}
                _active={bgFocus}
                w="37px"
                h="37px"
                lineHeight="100%"
                onClick={onOpen1}
                borderRadius="5px"
            >
                {selected !== ''
                    ? (
                        <Icon as={MdFilterAlt} color={'green.500'} w="24px" h="24px" />
                    )
                    : (
                        <Icon as={MdFilterAlt} color={textColor} w="24px" h="24px" />
                    )}
            </MenuButton>
            <MenuList
                w="150px"
                minW="unset"
                maxW="150px !important"
                border={'1px solid ' + borderColor}
                backdropFilter="blur(63px)"
                bg={bgList}
                boxShadow={bgShadow}
                borderRadius="5px"
            >
                {list.map((menuItem, index) => {
                    return (
                        <MenuItem
                            key={index}
                            transition="0.2s linear"
                            color={textColor}
                            _hover={textHover}
                            p="5px"
                            borderRadius="8px"
                            _active={{
                                bg: 'transparent'
                            }}
                            _focus={{
                                bg: 'transparent'
                            }}
                            justifyContent="center"
                            onClick={()=> handleChange(menuItem.value)}
                        >
                            <Flex align="center">
                                <Text
                                    fontSize="sm"
                                    fontWeight="400"
                                    color={selected === menuItem.value ? 'green.500' : ''}
                                >
                                    {menuItem.label}
                                </Text>
                            </Flex>
                        </MenuItem>
                    );
                })}
            </MenuList>
        </Menu>
    );
}
