import React from 'react';
import { Tag , Grid, GridItem } from '@chakra-ui/react';
import NoDataCard from 'components/Cards/NoDataCard';
import LoadingDataCard from 'components/Cards/LoadingDataCard';
import TabField from 'components/dataDispaly/TabField';
import ErrorCard from 'components/Cards/ErrorCard';

import { getStateView } from 'utils/address';
import { viewDate , viewDateAndTime } from 'utils/date';

const RealtorCompany = ({ data,isLoading,isError,refetch })=>{
    return (isLoading)
        ? (<LoadingDataCard />)
        : (isError)
            ? (<ErrorCard handleRefresh={refetch} />)
            : data
                ? (
                    <>
                        <div className='preview-box'>
                            <div className="preview-heading space-between-box">
                Company Details
                            </div>
                            <div className='outline-table '>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                Company Name
                                            </td>
                                            <td>
                                                {data?.companyName}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Company Bio
                                            </td>
                                            <td>
                                                {data?.companytext}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Email
                                            </td>
                                            <td>
                                                {data?.email }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Phone Number
                                            </td>
                                            <td>
                                                {data?.phoneNumber}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Website
                                            </td>
                                            <td>
                                                {data?.website}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Address Line 1
                                            </td>
                                            <td>
                                                {data?.Address1?.Address}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Address Line  2
                                            </td>
                                            <td>
                                                {data?.Address2?.Address}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                 City
                                            </td>
                                            <td>
                                                {data?.Address2?.City}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                    State
                                            </td>
                                            <td>
                                                {data?.Address2?.State ? getStateView(data?.Address2?.State) : '' }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Zipcode
                                            </td>
                                            <td>
                                                {data?.Address2?.Zipcode}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
            Created On
                                            </td>
                                            <td>
                                                {viewDate(data?.createdAt)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                Updated On
                                            </td>
                                            <td>
                                                {viewDate(data?.updatedAt)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div className="preview-box">
                            <div className="preview-heading space-between-box">
                    State Licenses
                            </div>

                            <div className="outline-table">
                                {
                                    (data && data?.Statelicenses.length > 0 && data?.Statelicenses?.map((state,index)=>(
                                        <Tag colorScheme='teal' key={index} m={2} size='md'>{state.label}</Tag>
                                    ))) || <TabField value={<p>Doesn&apos;t have any state license</p>}/>
                                }

                            </div>
                        </div>

                        <div className="preview-box">
                            <div className="preview-heading space-between-box">
                    Logo
                            </div>
                            <div className='content'>
                                <Grid templateColumns='repeat(5, 1fr)' >
                                    <GridItem colSpan={3} >
                                        {
                                            data?.logo
                                                ? <img loading={'lazy'} src={data?.logo} style={{ width: '90%', height: '90%' }} alt="Logo" />
                                                : <p className='padding-box'>No Logo added</p>

                                        }

                                    </GridItem>
                                    <GridItem colSpan={1} >
                                        <p>Logo Added</p>
                                        {viewDateAndTime(data?.createdAt)}
                                    </GridItem>
                                    <GridItem colSpan={1} >
                                        <p>Last Updated On</p>
                                        {viewDateAndTime(data?.updatedAt)}
                                    </GridItem>

                                </Grid>

                            </div>
                        </div>
                    </>)
                : <NoDataCard label={'No Details Available'}/>;
};

export default RealtorCompany;
