import React from 'react';
import sortCaret from 'components/Table/Others/sortCaret';
import { Link } from '@chakra-ui/react';
import { viewDateAndTime } from 'utils/date';

export const getUsersTableHeader = () => {
    return [
        {
            text: 'Email',
            dataField: 'ContactPointEmailValue',
            sort: true,
            sortCaret,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Link href={'/organization/user/view-user/' + row._id} color='brand.500'>
                        {cell}
                    </Link>
                );
            }
        },
        {
            text: 'First Name',
            dataField: 'FirstName',
            sort: true,
            sortCaret
        },
        {
            text: 'Last Name',
            dataField: 'LastName',
            sort: true,
            sortCaret
        },
        {
            text: 'Gender',
            dataField: 'gender',
            sort: true,
            sortCaret
        },
        {
            text: 'Mobile No',
            dataField: 'ContactPointTelephonePrefix',
            formatter: (value, row)=>{
                return '+' + row?.ContactPointTelephonePrefix + ' ' + row?.ContactPointTelephoneValue;
            },
            sortCaret,
            sort: true

        },
        {
            text: 'Created At',
            dataField: 'createdAt',
            sort: true,
            sortCaret,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return viewDateAndTime(cell);
            }
        },
        {
            text: 'Action',
            dataField: '_id',
            sort: true,
            sortCaret,
            formatter: (cell, row, rowIndex, formatExtraData) => {
                return (
                    <Link href={'/organization/user/view-user/' + cell} color='brand.500'>
                    View Details
                    </Link>
                );
            }
        }

    ];
};
