import React from 'react';

/** ************************ COMPONENTS  ***********************8 */
import { Button,Slide , Input,Modal, ModalOverlay , ModalHeader , ModalCloseButton , ModalBody , ModalFooter, ModalContent, Text , FormControl } from '@chakra-ui/react';
/** *********************** FORM  FIELDS  **************88 */
import { Formik,Field, Form,ErrorMessage } from 'formik';
import { InviteModalScheme } from 'validations';
/** ************************ HELPERS  **************** */
import { useInviteRealtorMutation } from 'reducers/realtor.service';

const InviteModal = ({ onClose, isOpen })=>{
    const [inviteRealtor,{ isLoading }] = useInviteRealtorMutation();
    const handleInvite = (values)=>{
        inviteRealtor(values).unwrap().then(()=>{
            onClose();
        });
    };
    return (
        <Slide direction='top' in={isOpen}>
            <Modal isCentered blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} >
                <ModalOverlay />
                <Formik
                    initialValues={{
                        email: ''
                    }}
                    onSubmit={handleInvite}
                    enableReinitialize={true}
                    validationSchema={InviteModalScheme}
                >
                    {({ handleSubmit, values }) => {
                        return (
                            <Form
                                onSubmit={handleSubmit}
                                name="user-edit-form"
                                className="full-width"
                            >
                                <ModalContent>
                                    <ModalHeader>Invite Realtor</ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                        <Text fontWeight='bold' mb='1rem'>
                                            Enter below the email of realtor you want to invite
                                        </Text>
                                        <br />
                                        <FormControl w="100%">
                                            <Field
                                                isRequired={false}
                                                as={Input}
                                                fontSize="sm"
                                                ms={{ base: '0px', md: '0px' }}
                                                type="email"
                                                name="email"
                                                placeholder="Enter email here..."
                                                mb="12px"
                                                fontWeight="500"
                                                size="lg"
                                            />
                                            <ErrorMessage name="email">
                                                <Text color={'red'}>
                                                    {/* add here */}
                                                </Text>
                                            </ErrorMessage>
                                        </FormControl>
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button mr={3} onClick={onClose}>
                            Close
                                        </Button>
                                        <Button type='submit' isLoading={isLoading} loadingText={'Sending.. '} variant='brand'>
                                          Send Invite
                                        </Button>
                                    </ModalFooter>
                                </ModalContent>
                            </Form>
                        );
                    }
                    }
                </Formik>
            </Modal>
        </Slide>
    );
};

export default React.memo(InviteModal);
