import { baseQuerySetup,getResponsedata,getResponseResult,onQueryStarted, onCacheEntryAdded } from 'config/index';
import { createApi } from '@reduxjs/toolkit/query/react';
let dashboardReducer = 'DashboardReducer';
let dashboardTag = 'DashboardTag';
let newUsersTag = 'NewUsersTag';

export const dashboardApi = createApi({
    reducerPath: dashboardReducer,
    baseQuery: baseQuerySetup,
    tagTypes: [dashboardTag],
    endpoints: (builder) => ({
        getDashboardstats: builder.query({
            query: () => {
                return { url: '/dashboard/stats' , method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponsedata,
            providesTags: [dashboardTag]
        }),
        getNewUsers: builder.query({
            query: () => {
                return { url: '/dashboard/new-users' , method: 'GET' };
            },
            onQueryStarted,
            onCacheEntryAdded,
            transformResponse: getResponseResult,
            providesTags: [newUsersTag]
        })
    })
});
export const { useGetDashboardstatsQuery,useGetNewUsersQuery } = dashboardApi;
