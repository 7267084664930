import countries from 'constants/Select-constants/countrylist.json';
import statelist from 'constants/Select-constants/stateslist.json';
import { ADDRESS_DEFAULT } from 'constants/index';
export const getCountryView = (value) => {
    const item = countries.find((row) => row.value === value);
    if (item) {
        return item.label;
    }
    return 'US';
};

export const getStateName = (value)=>{
    if (value) {
        const element = statelist.find((row)=> row.value === value);
        return element.label || 'N/A';
    }
    return 'N/A';
};

export const getStateValue = (value)=>{
    if (value) {
        const element = statelist.find((row)=> row.value === value);
        return element.value || 'NY';
    }
    return 'NY';
};

export const getStateValueFromLabel = (label)=>{
    if (label) {
        const element = statelist.find((row)=> row.label === label);
        return element?.value || 'NY';
    }
    return 'NY';
};
export const getStateView = (value) => {
    const item = statelist.find((row) => row.value === value);
    if (item) {
        return item.label;
    }
    return '';
};
export const getValueArray = (data)=>{
    if (data && data.length && data.length > 0) {
        data = data.map((row)=> row.value);
        return data;
    }
    return [];
};
export const getStateLicenseList = (data)=>{
    if (data && data.length && data.length > 0) {
        const final = data.map((value)=> {
            const label = getStateName(value);
            return { label, value };
        });
        return final;
    }
    return [];
};
export const getAddressString = (Address)=>{
    if (Address) {
        let state = getStateView(Address?.State);
        let country = getCountryView(Address?.Country);
        return Address?.Address + ' ,' + Address.City + ' , ' + state + ' , ' + Address?.Zipcode + ' , ' + country;
    }
    return 'N/A';
};
export const getApplicationAddress = (Address)=>{
    if (Address) {
        let state = getStateView(Address?.State);
        return state + ' , ' + Address?.Zipcode;
    }
    return 'N/A';
};

export const PrepareAddressData = (data)=>{
    const Address = {
        Address: data.Address, City: data.City, State: data.State, Country: data.Country, Zipcode: data.Zipcode
    };
    return { ...data , Address };
};

export const getAddressObject = (Address)=>{
    if (Address) {
        return {
            Address: Address?.Address || '',
            City: Address?.City || '',
            State: Address?.State || 'NY',
            Zipcode: Address?.Zipcode || '',
            Country: Address?.Country || 'US'
        };
    }
    return ADDRESS_DEFAULT;
};
