import React from 'react';
import TabField from 'components/dataDispaly/TabField';
import { viewDate } from 'utils/date';
import { getAddressString } from 'utils/address';
import { Box,Text,Flex, Grid,Tag,Divider } from '@chakra-ui/react';
import NoDataCard from 'components/Cards/NoDataCard';
import LoadingDataCard from 'components/Cards/LoadingDataCard';
import ErrorCard from 'components/Cards/ErrorCard';
const CompanyDetails = ({ data,isLoading,isError,refetch })=>{
    return (isLoading)
        ? (<LoadingDataCard />)
        : (isError)
            ? (<ErrorCard handleRefresh={refetch} />)
            : data
                ? (
                    <Box>
                        <Flex p='4' justifyContent={'space-between'}>
                            <Box>
                                <Text fontSize="3xl" fontWeight="bold" mb="1">
                                    {data?.companyId}
                                </Text>
                                <Text fontSize="3xl" fontWeight="bold" mb="2">
                                    {data?.name}
                                </Text>
                            </Box>
                            <Grid templateColumns={[null,'repeat(3, 1fr)',null,null,'repeat(5, 1fr)']} gap={30} pl={4} w={[null,'60%',null,null,'75%']} >
                                <TabField label={'NMLS'} value={data?.NMLS}/>
                                <TabField label={'Formed In'} value={ <Tag colorScheme='teal' size='md'>{data?.FormedIn}</Tag>} />
                                <TabField label={'Founded Date'} value={viewDate(data?.dateFormed)}/>
                                <TabField label={'On Boarding'} value={data?.isCompanySetup ? 'COMPLETED' : 'INPROGRESS'}/>

                            </Grid>
                        </Flex>
                        <Box p='4'>
                            <Text>State Licenses</Text>
                            <Divider />
                            <Grid pt={4} templateColumns='repeat(8, 1fr)' gap={2}>
                                {
                                    (data && data?.Statelicenses?.map((state,index)=>(
                                        <Tag colorScheme='teal' key={index} m={2}>{state.label}</Tag>
                                    ))) || <TabField value={<Tag colorScheme='red' size='md'>Doesn&apos;t have any state license</Tag>}/>
                                }
                            </Grid>
                        </Box>
                        <Box p='4'>
                            <Text>Address</Text>
                            <Divider />
                            <Grid pt={4} templateColumns={[null,'repeat(3, 1fr)',null,null,'repeat(4, 1fr)']} gap={40}>
                                <TabField label={'Physical Address'} value={data?.Address?.Address}/>
                                <TabField label={'City'} value={data?.Address?.City}/>
                                <TabField label={'State'} value={data?.Address?.State}/>
                                <TabField label={'Pincode'} value={data?.Address?.Zipcode}/>
                            </Grid>
                        </Box>
                        <Box p='4'>
                            <Text>Contact Information</Text>
                            <Divider/>
                            <Grid pt={4} templateColumns='repeat(5, 1fr)' gap={30}>
                                <TabField label={'Corporate Email Address'} value={data?.email}/>
                                <TabField label={'Cell Number'} value={data?.phone}/>
                                <TabField label={'Telephone'} value={data?.telNumber}/>
                                <TabField label={'Website'} value={data?.website}/>
                                <TabField label={'Fax'} value={data?.fax}/>
                                <TabField label={'Mailing Address'} value={getAddressString(data?.mailingaddress)}/>

                            </Grid>
                        </Box>
                    </Box>
                )
                : <NoDataCard label={'No Details Available'}/>;
};

export default CompanyDetails;
